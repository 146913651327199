<template>
	<div>
		<div class="container-md">
			<customer v-model="customer" class="mb-50" @validStatusChanged="updateValidStatus"></customer>

			<div class="flex align-start">
				<a href="#" class="btn" @click.prevent="saveCustomer()" :class="{ disabled: loading || formInvalid }">Rætta</a>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios';
import Customer from '@/components/forms/Customer.vue';

export default {
	name: 'CustomerEdit',

	components: {
		Customer,
	},

	data() {
		return {
			customerId: null,

			loading: false,
			formInvalid: true,

			customer: {
				//
			},
		};
	},

	mounted() {
		this.customerId = this.$route.params.id;

		axios
			.get(`/customers/${this.customerId}`)
			.then((response) => {
				this.customer = response.data;
			})
			.catch((error) => {
				console.log('error fetching customer', error);
			});
	},

	methods: {
		updateValidStatus(status) {
			this.formInvalid = status;
		},

		saveCustomer() {
			if (!this.customerId) {
				return;
			}

			this.loading = true;

			const data = { ...this.customer };

			if (typeof data.mobileNumbers == 'object') {
				data.mobileNumbers = data.mobileNumbers.join(',');
			}

			axios
				.patch(`/customers/${this.customerId}`, data)
				.then(() => {
					this.$router.push({ name: 'CustomerShow', params: { id: this.customerId } });
				})
				.catch((error) => {
					console.log('error saving customer', error);
				})
				.finally(() => {
					this.loading = false;
				});
		},
	},
};
</script>
